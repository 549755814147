import { isNil as lo_isNil } from 'es-toolkit';

// TODO: Lang etc, will be set based on user/browser settings
const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function formatCurrency(val: number | string): string | void {
    if (lo_isNil(val) || Number.isNaN(val)) {
        return;
    }

    return formatter.format(parseFloat(`${val}`));
}

export function roundCurrencyValue(val: number): number | void {
    if (lo_isNil(val) || Number.isNaN(val)) {
        return;
    }

    return parseFloat(Math.fround(val).toFixed(2));
}
